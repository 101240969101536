var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottom-back" },
    [
      _c(
        "van-button",
        {
          attrs: {
            block: "",
            square: "",
            type: "info",
            "native-type": "button"
          },
          on: {
            click: function($event) {
              return _vm.utils.backReturn(_vm.$route.query.messageType)
            }
          }
        },
        [_vm._v(" 返回 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }