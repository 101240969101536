<template>
  <div class="bottom-back">
    <van-button block square type="info" native-type="button" @click="utils.backReturn($route.query.messageType)">
      返回
    </van-button>
  </div>
</template>

<script>
import { Button } from 'vant'
export default {
  name: 'BackButton',
  components: {
    [Button.name]: Button
  },
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
  .bottom-back {
    position: fixed;
    bottom: 0px;
    height: 55px;
    width: calc(100% - 20px);
    left: 50%;
    margin-left: calc(-50% + 10px);
    background-color: #FFF;
    z-index: 11;
  }
</style>
