var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main order-list" },
    [
      _c("van-search", {
        staticClass: "van-main-search",
        attrs: {
          shape: "round",
          "left-icon": "",
          "show-action": "",
          placeholder: "可输入订单编号/订单名称查询"
        },
        on: { search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c("div", { on: { click: _vm.onSearch } }, [_vm._v(" 搜索 ")])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.searchForm.searchValue,
          callback: function($$v) {
            _vm.$set(_vm.searchForm, "searchValue", $$v)
          },
          expression: "searchForm.searchValue"
        }
      }),
      _c(
        "div",
        { staticClass: "van-refresh-list" },
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "div",
                    { key: item.orderNo, staticClass: "order-item" },
                    [
                      _c("div", [
                        _c("div", { staticClass: "fl" }, [
                          _c("p", [
                            _c("span", [_vm._v("订单编号")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(item.orderNo))])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("订单名称")]),
                            _c("span", [_vm._v(_vm._s(item.orderName))])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("产品类型")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.statusFormat(
                                    String(item.productType),
                                    "ProjectProductType"
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("p", [
                            _c("span", [_vm._v("创建时间")]),
                            _c("span", [_vm._v(_vm._s(item.createTimeStr))])
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "fl" },
                          [
                            _vm.utils.checkSystemType(
                              _vm.constants.systemType.gzbSystem
                            )
                              ? _c("p", { staticClass: "order-status" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.utils.statusFormat(
                                          item.orderStatus,
                                          "orderStatus"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _c("p", { staticClass: "order-status" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.utils.statusFormat(
                                          String(item.orderStatus),
                                          "OrderStatus"
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                            _c(
                              "van-button",
                              {
                                attrs: {
                                  plain: "",
                                  round: "",
                                  size: "small",
                                  type: "info",
                                  "native-type": "button"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.detail(item)
                                  }
                                }
                              },
                              [_vm._v(" 查看详情 ")]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "clearfix" })
                      ]),
                      _c("van-divider")
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }